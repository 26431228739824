import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import { MHPageProps } from "../types"
import { PostListForMDX } from "../components/PostList"

class BlogIndex extends React.Component<MHPageProps> {
  render() {
    const { data } = this.props

    return (
      <>
        <SEO title="All posts" location={this.props.location} />

        <h1 className="n-text-centered n-vspacing-large">Stuff I've written</h1>
        <PostListForMDX posts={data.allMdx.edges} />
      </>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query GetPostsQuery($publishedFilter: BooleanQueryOperatorInput) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        fields: { contentType: { eq: "posts" } }
        frontmatter: { published: $publishedFilter }
      }
    ) {
      edges {
        node {
          id
          ...MHExcerpt
          fields {
            slug
            contentType
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            published
            description
          }
          wordCount {
            # For measuring if this page should have a permanent link
            words
          }
        }
      }
    }
  }
`
